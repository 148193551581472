.root {
	--padding-y: calc(var(--unit) * 12.5);
	display: flex;
	box-sizing: border-box;
	width: 100%;
	max-width: 1360px;
	margin: 0 auto;
	padding-right: 20px;
	padding-bottom: var(--padding-y);
	padding-left: 20px;
	flex-direction: column;
	background-color: rgb(var(--kit-colors-pure-800));

	@media (--tablet) {
		padding-bottom: calc(var(--unit-x5));
	}
}

.container {
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media (--tablet) {
		flex-direction: row;
	}
}

.contacts {
	display: flex;
	min-width: 290px;
	max-width: 290px;
	flex-direction: column;
	gap: 58px;
	justify-content: space-between;
}

.cols {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	row-gap: 34px;
	justify-content: space-between;

	@media screen and (min-width: 547px) {
		justify-content: flex-start;
	}

	@media (--tablet) {
		justify-content: flex-end;
	}
}

.marginTop {
	padding-top: var(--padding-y);
}

.cityList {
	@media (--tablet-down) {
		margin-bottom: calc(var(--unit) * 12.5);
	}
}

.cityListItem {
	&& {
		@media (--tablet-down) {
			width: 50%;

			&:nth-child(even) {
				--offset: 10px;

				width: calc(50% - var(--offset));
				margin-left: var(--offset);
			}
		}
	}
}

.policyList {
	margin-bottom: calc(var(--unit) * 7.5);
}

.divider {
	width: 100%;
	border-top: 1px solid rgb(var(--kit-colors-charcoal-100));
	margin: 24px 0;
}

.mobileDivider {
	composes: divider;
	margin: 40px 0;

	@media (--tablet) {
		display: none;
	}
}

.hideMobile {
	display: none;

	@media (--tablet) {
		display: block;
	}
}

.hideDesktop {
	display: block;

	@media (--tablet) {
		display: none;
	}
}

.copyright {
	display: block;
	color: rgb(var(--kit-colors-charcoal-800));
	text-align: center;

	@media (--tablet) {
		margin-top: 0;
	}
}

.logo {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.juneLogo {
	height: 50px;
}
