.root {
	margin-right: 10px;
	margin-left: 10px;

	@media (--tablet) {
		display: flex;
		box-sizing: border-box;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
	}
}

.wrapper {
	display: flex;
	justify-content: center;
}

.item {
	composes: taptic from '~assets/styles/composes.css';
	display: inline-flex;
	height: var(--header-height);
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: inherit;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	cursor: pointer;

	@media (--tablet) {
		width: auto;
		flex-direction: row;
	}
}

.active {
	&,
	& svg {
		color: rgb(var(--color-sark));
	}
}

.dropdown {
	position: absolute;
	top: 100%;
	z-index: calc(var(--level-popup) + 2);
	display: none;
}

.dropdownShow {
	display: block;
}

.dropdownLeft {
	left: 0;
}

.dropdownRight {
	right: 0;
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	z-index: calc(var(--level-float) + 1);
	width: 100%;
	height: 100%;
	background-color: #0000003d;
}

.relativeEl {
	position: relative;
}

.fullwidth {
	right: 0;
	width: calc(100% + calc(var(--unit-x4) * 2));
	margin-right: calc(var(--unit-x4) * -1);
	margin-left: calc(var(--unit-x4) * -1);
}
