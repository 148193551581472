.list {
	display: flex;
	margin: 0;
	padding: 0;
	flex-direction: column;
	grid-gap: var(--unit-x3);
	list-style-type: none;

	@media (--tablet) {
		width: 100%;
		margin-top: 24px;
		padding-bottom: 12px;
		flex-direction: row;
		overflow: auto;
	}
}

.listItem {
	position: relative;
}

.listItemLink {
	display: block;
	height: 100%;
}

.cityCard {
	user-select: none;
}

.grayscale {
	filter: grayscale(1);
	pointer-events: none;
}
