.root {
	position: relative;
	color: rgb(var(--color-lunar));
}

.selector {
	position: relative;
}

.selectorActive {
	@media (--tablet) {
		z-index: var(--level-popup);
	}
}

.selectorUnder {
	@media (--tablet) {
		z-index: 0;
	}
}
