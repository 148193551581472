.startIcon,
.endIcon,
.text {
	transition: opacity 100ms linear;
}

/* variant=primary1, size=S */

.button {
	position: relative;
	display: inline-flex;
	width: var(--btn-width, auto);
	min-width: var(--min-width);
	min-height: var(--min-height);
	border: var(--border-width) solid var(--border-color);
	border-radius: var(--border-radius);
	padding: var(--padding-y) var(--padding-x);
	align-items: center;
	background-color: var(--background-color);
	color: var(--color);
	outline: 0;
	font-weight: 500;
	font-size: 12px;
	line-height: 1.67;
	transition: background-color 75ms ease-in 50ms, color 75ms ease-in 50ms, border-color 75ms ease-in 50ms;

	&,
	&:before,
	&:after {
		--color: rgb(var(--kit-colors-pure-800));
		--background-color: rgb(var(--kit-colors-cobalt-800));
		--border-color: transparent;
		--border-width: 0px;
		--outline-color: rgb(var(--kit-colors-cobalt-800));
		--outline-width: 1px;
		--outline-padding: 0px;
		--padding-x: 25px;
		--padding-y: 5px;
		--min-width: 122px;
		--min-height: 30px;
		--border-radius: 100px;
		--icon-size: 14px;
		--spinner-color: rgb(var(--kit-colors-pure-800));

		/* Hover */

		--hover-color: rgb(var(--kit-colors-pure-800));
		--hover-background-color: rgb(var(--kit-colors-cobalt-600));
		--hover-outline-color: rgb(var(--kit-colors-cobalt-800));
		--hover-border-color: transparent;

		/* Focus */

		--focus-color: rgb(var(--kit-colors-pure-800));
		--focus-background-color: rgb(var(--kit-colors-cobalt-800));
		--focus-outline-padding: 2px;
		--focus-outline-color: rgb(var(--kit-colors-cobalt-800));

		/* Pressed */

		--pressed-color: rgb(var(--kit-colors-pure-800));
		--pressed-background-color: #1d4395;
		--pressed-border-color: transparent;

		/* Disabled */

		--disabled-background-color: rgb(var(--kit-colors-pure-200));
		--disabled-outline-color: rgb(var(--kit-colors-charcoal-200));
		--disabled-color: rgb(var(--kit-colors-charcoal-200));
		--disabled-border-color: transparent;
	}

	&,
	& * {
		box-sizing: border-box;
	}

	& > .text {
		margin: 0 auto;
		font-weight: var(--font-weight, inherit);
		font-size: var(--font-size, inherit);
	}

	&[data-loading='true'] {
		cursor: wait;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		content: '';
		width: calc(100% - 2px + (var(--outline-padding) * 2) + (var(--border-width) * 2));
		height: calc(100% - 2px + (var(--outline-padding) * 2) + (var(--border-width) * 2));
		border: var(--outline-width) solid var(--outline-color);
		border-radius: var(--border-radius);
		transform: translate(
			calc((var(--outline-padding) + var(--border-width)) * -1),
			calc((var(--outline-padding) + var(--border-width)) * -1)
		);
	}

	&:disabled {
		cursor: default;

		&,
		&:before,
		&:after {
			--color: var(--disabled-color);
			--background-color: var(--disabled-background-color);
			--outline-color: var(--disabled-outline-color);
			--border-color: var(--disabled-border-color);
		}
	}

	&:not(:disabled, &[data-loading='true']) {
		&:active {
			&,
			&:before,
			&:after {
				--color: var(--pressed-color);
				--background-color: var(--pressed-background-color);
				--border-color: var(--pressed-border-color);
			}
		}

		&:not(:active) {
			&:focus,
			&:focus-visible {
				&,
				&:before,
				&:after {
					--color: var(--focus-color);
					--background-color: var(--focus-background-color);
					--outline-color: var(--focus-outline-color);
					--outline-padding: var(--focus-outline-padding);
				}
			}

			&:not(:focus, :focus-visible):hover {
				&,
				&:before,
				&:after {
					--color: var(--hover-color);
					--background-color: var(--hover-background-color);
					--outline-color: var(--hover-outline-color);
					--border-color: var(--hover-border-color);
				}
			}
		}
	}
}

.size {
	&M {
		&,
		&:before,
		&:after {
			--padding-y: 8px;
		}
	}

	&L {
		&,
		&:before,
		&:after {
			--padding-y: 12px;
			--padding-x: 30px;
		}
	}
}

.buttonPrimary2 {
	&,
	&:before,
	&:after {
		--color: rgb(var(--kit-colors-charcoal-800));
		--background-color: rgb(var(--kit-colors-pure-800));
		--border-width: 1px;
		--border-color: rgb(var(--kit-colors-pure-200));
		--outline-color: transparent;
		--hover-color: rgb(var(--kit-colors-charcoal-800));
		--hover-outline-color: transparent;
		--hover-background-color: rgb(var(--kit-colors-pure-600));
		--hover-border-color: rgb(var(--kit-colors-charcoal-400));
		--focus-color: rgb(var(--kit-colors-charcoal-800));
		--focus-background-color: rgb(var(--kit-colors-pure-800));
		--pressed-color: rgb(var(--kit-colors-charcoal-800));
		--pressed-background-color: rgb(var(--kit-colors-pure-400));
		--pressed-border-color: rgb(var(--kit-colors-charcoal-400));
		--disabled-color: rgb(var(--kit-colors-charcoal-200));
		--disabled-background-color: rgb(var(--kit-colors-pure-700));
		--disabled-border-color: rgb(var(--kit-colors-charcoal-100));
		--disabled-outline-color: transparent;
	}
}

.buttonSecondary1 {
	&,
	&:before,
	&:after {
		--color: rgb(var(--kit-colors-pure-800));
		--background-color: rgb(var(--kit-colors-charcoal-600));
		--outline-color: rgb(var(--kit-colors-charcoal-200));
		--hover-color: rgb(var(--kit-colors-pure-800));
		--hover-background-color: rgb(var(--kit-colors-charcoal-800));
		--hover-outline-color: rgb(var(--kit-colors-charcoal-200));
		--focus-color: rgb(var(--kit-colors-pure-800));
		--focus-background-color: rgb(var(--kit-colors-charcoal-600));
		--pressed-background-color: rgb(var(--kit-colors-charcoal-400));
		--disabled-background-color: rgb(var(--kit-colors-pure-700));
		--disabled-outline-color: rgb(var(--kit-colors-charcoal-100));
	}
}

.buttonDanger1 {
	&,
	&:before,
	&:after {
		--color: rgb(var(--kit-colors-pure-800));
		--background-color: rgb(var(--kit-colors-danger-600));
		--outline-color: rgb(var(--kit-colors-danger-600));
		--hover-color: rgb(var(--kit-colors-pure-800));
		--hover-background-color: rgb(var(--kit-colors-danger-400));
		--hover-outline-color: rgb(var(--kit-colors-danger-400));
		--focus-color: rgb(var(--kit-colors-pure-800));
		--focus-background-color: rgb(var(--kit-colors-danger-600));
		--pressed-background-color: rgb(var(--kit-colors-danger-800));
		--disabled-background-color: rgb(var(--kit-colors-pure-600));
		--disabled-outline-color: rgb(var(--kit-colors-charcoal-200));
	}
}

.buttonDanger2 {
	&,
	&:before,
	&:after {
		--color: rgb(var(--kit-colors-danger-800));
		--background-color: rgb(var(--kit-colors-pure-800));
		--border-width: 1px;
		--border-color: rgb(var(--kit-colors-danger-600));
		--outline-color: transparent;
		--hover-color: rgb(var(--kit-colors-danger-800));
		--hover-outline-color: transparent;
		--hover-background-color: rgb(var(--kit-colors-danger-100));
		--hover-border-color: rgb(var(--kit-colors-danger-400));
		--focus-color: rgb(var(--kit-colors-danger-800));
		--focus-background-color: rgb(var(--kit-colors-pure-800));
		--pressed-color: rgb(var(--kit-colors-pure-800));
		--pressed-background-color: rgb(var(--kit-colors-danger-800));
		--pressed-border-color: rgb(var(--kit-colors-danger-800));
		--disabled-color: rgb(var(--kit-colors-charcoal-200));
		--disabled-background-color: rgb(var(--kit-colors-pure-600));
		--disabled-border-color: rgb(var(--kit-colors-charcoal-200));
		--disabled-outline-color: transparent;
	}
}

.buttonSecondary1,
.buttonDanger1 {
	&,
	&:before,
	&:after {
		--spinner-color: rgb(var(--kit-colors-pure-800));
	}
}

.buttonPrimary2,
.buttonDanger2 {
	&,
	&:before,
	&:after {
		--spinner-color: rgb(var(--kit-colors-night));
	}
}

.iconSizeS {
	&,
	&:before,
	&:after {
		--icon-size: 8px;
	}
}

.startIcon,
.endIcon {
	display: flex;
	width: var(--icon-size);
	height: var(--icon-size);
}

.startIcon {
	margin-right: 10px;
}

.endIcon {
	margin-left: 10px;
}

.hidden {
	opacity: 0;
}

.noMinWidth {
	min-width: auto;
}

.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	width: 16px;
	height: 16px;
	margin-top: -8px;
	margin-left: -8px;
	color: var(--spinner-color);
	transform: translate(-50%, -50%);
	animation: spin 1s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(-360deg);
	}
}

.fill {
	display: flex;
	width: 100%;
}
