.root {
	display: flex;
	flex-wrap: wrap;
}

.item {
	--select-box-color: rgb(var(--color-lunar));
	--select-box-padding: 10px 16px;

	box-sizing: border-box;
	width: 100%;
	padding: 8px 0;
	flex-shrink: 0;
}

.itemHalf {
	@media (--tablet) {
		width: 50%;

		&:nth-child(odd) {
			padding-right: 16px;
		}
	}
}

.value {
	position: relative;
	display: flex;
	padding-left: 46px;
	align-items: center;
}

.name {
	padding: 10px 0;
	line-height: 1;
	text-align: left;
}

.name b {
	display: block;
	padding-top: 4px;
	font-weight: 400;
	font-size: var(--text-s);
}

.comingSoon {
	padding: 2px 0;
}

.icon {
	position: absolute;
	left: 0;
	width: 42px;
	height: 42px;
	margin-right: 8px;
	flex-shrink: 0;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		content: '';
		width: 50%;
		height: 100%;
		background-color: rgba(var(--color-sark), 0.04);
	}
}

.iconActive > * {
	filter: brightness(100);
}

.iconCity {
	display: flex;
	align-items: center;
	justify-content: center;
}

.iconCity.disabled {
	filter: grayscale(0.8);
	opacity: 0.8;
}
