.root {
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: var(--layer04);
	display: none;

	box-sizing: border-box;
	width: 56px;
	height: 56px;
	border-radius: 200px;
	align-items: center;
	background: rgb(var(--color-lunar));
	color: rgb(var(--color-white));
	text-decoration: none;
	transition: transform 0.2s ease-in-out;
	transform: scale(1);

	@supports (bottom: env(safe-area-inset-bottom)) {
		bottom: calc(env(safe-area-inset-bottom) + 20px);
	}

	@media (--tablet) {
		bottom: var(--horizontal-offset);
		left: var(--horizontal-offset);
		display: inline-flex;
	}

	@media (--laptop) {
		left: var(--horizontal-offset);
	}

	@media (min-width: 1212px) {
		left: calc((100% - var(--container-width)) / 2);
	}

	@media (min-width: 1358px) {
		right: calc((100% - var(--container-width)) / 2 - var(--horizontal-offset) - 56px);
		left: auto;
	}
}

.root:hover {
	transform: scale(1.2);

	@media (--tablet) {
		width: 256px;
		transform: scale(1);
	}
}

.rootLight {
	background: #fff;
}

.rootLight:hover {
	@media (--tablet) {
		background: rgb(var(--color-lunar));
	}
}

.icon {
	position: absolute;
	z-index: var(--level-common);
	width: 28px;
	height: 28px;
	transition: transform 0.2s ease-out;
	transform: scale(1);
	opacity: 1;

	& > svg {
		width: 100%;
		height: 100%;
		margin: 0 14px;
	}
}

.iconLight {
	color: rgb(var(--color-lunar));
}

.content {
	min-width: 256px;
	margin-left: 12px;
	font-weight: 400;
	font-size: 12px;
	transition: opacity 0.2s ease-out;
	opacity: 0;
}

.root:hover .content {
	@media (--tablet) {
		opacity: 1;
	}
}

.phone {
	font-weight: 700;
	font-size: 14px;
	line-height: 144%;
}

.root:hover .icon {
	@media (--tablet) {
		transform: scale(0.5);
		opacity: 0;
	}
}

.avatar {
	height: 56px;
	border-radius: 50%;
	flex-shrink: 0;
	overflow: hidden;
	transition: opacity 0.2s ease-in-out;
	opacity: 0;
}

.root:hover .avatar {
	@media (--tablet) {
		border-radius: 50%;
		opacity: 1;
	}
}
