.root {
	position: relative;
	display: flex;
	box-sizing: border-box;
	min-height: 40px;
	padding: 8px 56px;
	padding-left: 0;
	background: rgb(var(--color-sark));
	color: rgb(var(--color-white));
	text-decoration: none;
	cursor: pointer;

	@media (--tablet) {
		margin: 0 calc(-1 * var(--horizontal-offset));
	}
}

.content {
	margin: auto;
}

.close {
	composes: taptic from '~assets/styles/composes.css';

	position: absolute;
	top: calc(50% - 12px);
	right: 12px;
	display: flex;
	width: 24px;
	height: 24px;
	border: 0;
	border-radius: 50%;
	margin: 0;
	padding: 0;
	align-items: center;
	justify-content: center;
	background: #fff;
	color: rgb(var(--color-black));
	box-shadow: 2px 2px 17px rgba(0, 0, 0, 0.1);
	appearance: none;

	&:before {
		position: absolute;
		top: -3px;
		left: -3px;
		display: block;
		content: '';
		width: 30px;
		height: 30px;
	}
}

.close > div {
	width: 12px;
	height: 12px;
}
