:root,
:before,
:after {
	/* UI Kit */

	--kit-font-primary: Avenir, system-ui, sans-serif;

	/* Cobalt */

	--kit-colors-cobalt: 39, 89, 197;
	--kit-colors-cobalt-900: 29, 67, 149;
	--kit-colors-cobalt-800: var(--kit-colors-cobalt);
	--kit-colors-cobalt-600: 63, 112, 217;
	--kit-colors-cobalt-400: 106, 144, 226;
	--kit-colors-cobalt-200: 149, 176, 234;
	--kit-colors-cobalt-100: 212, 223, 247;

	/* Citron */

	--kit-colors-citron: 219, 219, 73;
	--kit-colors-citron-800: var(--kit-colors-citron);
	--kit-colors-citron-600: 223, 223, 93;
	--kit-colors-citron-400: 230, 230, 127;
	--kit-colors-citron-200: 238, 238, 170;
	--kit-colors-citron-100: 247, 247, 212;

	/* Tangerine */

	--kit-colors-tangerine: 235, 102, 56;
	--kit-colors-tangerine-800: var(--kit-colors-tangerine);
	--kit-colors-tangerine-600: 237, 120, 79;
	--kit-colors-tangerine-400: 241, 148, 116;
	--kit-colors-tangerine-200: 246, 184, 162;
	--kit-colors-tangerine-100: 250, 219, 209;

	/* Grass */

	--kit-colors-grass: 6, 166, 115;
	--kit-colors-grass-800: var(--kit-colors-grass);
	--kit-colors-grass-600: 64, 191, 151;
	--kit-colors-grass-400: 121, 210, 182;
	--kit-colors-grass-200: 179, 229, 213;
	--kit-colors-grass-100: 217, 242, 234;

	/* Orchid */

	--kit-colors-orchid: 242, 140, 243;
	--kit-colors-orchid-800: var(--kit-colors-orchid);
	--kit-colors-orchid-600: 245, 163, 245;
	--kit-colors-orchid-400: 247, 186, 248;
	--kit-colors-orchid-200: 250, 209, 250;
	--kit-colors-orchid-100: 252, 232, 253;

	/* Charcoal */

	--kit-colors-charcoal: 34, 36, 38;
	--kit-colors-charcoal-800: var(--kit-colors-charcoal);
	--kit-colors-charcoal-600: 72, 77, 81;
	--kit-colors-charcoal-400: 108, 115, 121;
	--kit-colors-charcoal-200: 147, 153, 159;
	--kit-colors-charcoal-100: 188, 191, 195;

	/* Pure */

	--kit-colors-pure: 255, 255, 255;
	--kit-colors-pure-800: var(--kit-colors-pure);
	--kit-colors-pure-700: 250, 250, 250;
	--kit-colors-pure-600: 242, 242, 242;
	--kit-colors-pure-650: 245, 245, 245;
	--kit-colors-pure-400: 230, 230, 230;
	--kit-colors-pure-200: 217, 217, 217;
	--kit-colors-pure-100: 204, 204, 204;

	/* Danger */

	--kit-colors-danger: 235, 56, 56;
	--kit-colors-danger-800: var(--kit-colors-danger);
	--kit-colors-danger-600: 237, 79, 79;
	--kit-colors-danger-400: 241, 116, 116;
	--kit-colors-danger-200: 246, 162, 162;
	--kit-colors-danger-100: 250, 209, 209;

	/* Common */

	--kit-colors-backgrounds: 246, 246, 246;
	--kit-colors-borders: 232, 233, 234;
	--kit-colors-night: 0, 0, 0;

	/* Common */

	--unit: 4px;
	--unit-x2: 8px;
	--unit-x3: 12px;
	--unit-x4: 16px;
	--unit-x5: 20px;
	--unit-x6: 24px;
	--unit-x7: 28px;
	--unit-x8: 32px;
	--unit-x9: 36px;
	--unit-x10: 40px;
	--unit-x11: 44px;
	--unit-x12: 48px;
	--horizontal-offset: var(--unit-x2);
	--header-height: 53px;
	--filter-bar-height: 68px;
	--back-button-offset: 100px;
	--title-hero: 56px;
	--title-h1: 44px;
	--title-h2: 36px;
	--title-h3: 32px;
	--title-h4: 24px;
	--title-h5: 20px;
	--text-xl: 18px;
	--text-l: 16px;
	--text-m: 14px;
	--text-s: 12px;
	--text-ssm: 13px; /* FOR GOTHAM FONT */
	--text-xs: 10px;
	--text-xxs: 9px;
	--font-main: var(--kit-font-primary);
	--font-serif: Caslon, Georgia, Times New Roman, serif;
	--radius-s: var(--unit);
	--radius-m: var(--unit-x2);
	--radius-l: var(--unit-x4);
	--animate-duration: 1s;
	--animate-delay: 0;
	--animate-repeat: 1;

	/* Levels */

	--level-under: 0;
	--level-common: 1;
	--level-float: 300;
	--level-header: calc(var(--level-float) + 2);
	--level-passed-steps: var(--level-header);
	--level-booktour: calc(var(--level-header) + 1);
	--level-banner: calc(var(--level-header) + 1);
	--level-overlay: 500;
	--level-popup: 600;
	--level-fullscreen: 700;
	--level-progress: 900; /* NProgress */
	--level-god: 1000;

	/* Colors */

	--color-sark: 82, 121, 111;
	--color-mantle: 130, 157, 147;
	--color-lunar: 55, 58, 54;
	--color-action: 255, 112, 67;
	--color-panache: 250, 253, 251;
	--color-white: 255, 255, 255;
	--color-black: 0, 0, 0;
	--color-transparent: 255, 255, 255, 0;
	--color-placeholder: 117, 117, 117;

	/* Colors with opacity */

	/* Lunar with opacity */

	--color-lunar-75: 105, 107, 104;
	--color-lunar-50: 155, 156, 154;
	--color-lunar-25: 205, 206, 205;
	--color-lunar-10: 235, 235, 235;
	--color-lunar-4: 247, 247, 247;
	--color-lunar-5: 235, 235, 235;

	/* Black with opacity */

	--color-black-75: 64, 64, 64;
	--color-black-50: 128, 128, 128;
	--color-black-25: 191, 191, 191;
	--color-black-10: 230, 230, 230;
	--color-black-4: 245, 245, 245;

	/* Sark with opacity */

	--color-sark-75: 125, 154, 147;
	--color-sark-50: 168, 188, 183;
	--color-sark-25: 220, 228, 226;
	--color-sark-10: 238, 242, 241;
	--color-sark-4: 248, 250, 249;

	/* Action with opacity */

	--color-action-10: 255, 241, 236;

	/* Mantle with opacity */

	--color-mantle-80: 243, 245, 244;

	/* Icons */

	--icon-line-color: rgb(var(--color-lunar));
	--icon-fill-color: rgb(var(--color-sark));

	/* Old */

	--layer01: 100; /* Base */
	--layer02: 200; /* Header */
	--layer03: 300; /* Notifications */
	--layer04: 400; /* Floating notifications */
	--layer05: 500; /* Popups */
	--primary-color: #db5c23;
	--primary-color-10: #db5c2310;
	--primary-color-40: #db5c2340;
	--secondary-color: #5757610a;
	--error-color: #ee2231;
	--light-box-shadow: 4px 8px 80px rgba(0, 0, 0, 0.08);
	--light-hover-box-shadow: 0 5px 13px 5px rgba(0, 0, 0, 0.09);
	--header-logo-width: 80px;

	@media (--tablet) {
		--horizontal-offset: var(--unit-x4);
		--header-height: 52px;
		--header-logo-width: 80px;
		--back-button-offset: 0;
	}

	@media (--laptop) {
		--header-logo-width: 160px;
		--container-width: 1180px;
	}
}

html,
body,
#__next {
	height: 100%;
	scrollbar-gutter: stable;
}

html {
	min-width: 320px;
	min-height: 100%;
	background-color: #fff;
	font-size: var(--text-m);
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
}

body {
	height: 100%;
	margin: 0;
	color: rgb(var(--color-lunar));
	font-family: var(--font-main);
	line-height: 1.44;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

h1 {
	font-weight: 700;
	font-size: var(--title-h1);
	line-height: 1.16;
	letter-spacing: -0.01em;
}

h2 {
	font-weight: 700;
	font-size: var(--title-h2);
	line-height: 1.16;
	letter-spacing: -0.01em;
}

h3 {
	font-weight: 700;
	font-size: var(--title-h3);
	line-height: 1.24;
}

h4 {
	font-weight: 700;
	font-size: var(--title-h4);
	line-height: 1.3;
}

h5 {
	font-weight: 500;
	font-size: var(--title-h5);
	line-height: 1.3;
}

a {
	color: rgb(var(--color-lunar));
}

img {
	position: relative;
	display: inline-block;

	&:before,
	&:after {
		position: absolute;
		top: 0;
		left: 0;

		display: flex;
		content: '⌧';
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		background-color: #ccc;
		color: #000;

		font-size: 16px;
		text-align: center;
	}
}

picture > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

input::-webkit-input-placeholder {
	color: rgb(var(--color-placeholder));
}

input,
textarea,
button {
	color: inherit;
	outline: none;
	font-family: var(--font-main);
}

button {
	cursor: pointer;
	user-select: none;
}

video:focus {
	outline: 0;
}

#__next {
	min-height: 100%;
}

#__notifications {
	position: fixed;
	right: 0;
	top: 42px;
	left: 0;
	z-index: calc(var(--level-fullscreen) + 1);
	box-sizing: border-box;
	width: 100%;
	padding: 0 8px;

	@media (--tablet) {
		top: 54px;
		max-width: 542px;
		height: 0px;
		margin: 0 auto;
		padding: 0 10px;
	}
}

/* https://github.com/hoaiduyit/react-pannellum/issues/38#issuecomment-725257517 */
/* stylelint-disable */
.pnlm-about-msg {
	/* stylelint-enable */
	visibility: hidden;

	& a {
		visibility: hidden;
	}
}

/* FrontApp chat */

div#front-chat-container {
	z-index: calc(var(--level-popup) + 1);
}

#front-chat-holder > div > div[role='button']:first-child {
	bottom: 88px !important;

	@media (--tablet) {
		bottom: 78px !important;
	}
}

button.needsclick[class*='kl-teaser'] {
	z-index: calc(var(--level-overlay)) !important;
}

body.popuplockscroll {
	overflow: hidden;
}

/* TODO: uncomment https://junehomes.atlassian.net/browse/APOD-484 */
/* @font-face {
	font-weight: 400;
	font-family: Caslon;
	src: url("../fonts/caslon-540.woff2") format("woff2");
	font-display: swap;
} */

/* @font-face {
	font-family: 'Avenir';
	src: url("../fonts/AvenirNextLTPro-Regular.woff2") format('woff2');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
} */

/* @font-face {
	font-family: 'Avenir';
	src: url("../fonts/AvenirNextLTPro-Medium.woff2") format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
} */

/* @font-face {
	font-family: 'Avenir';
	src: url("../fonts/AvenirNextLTPro-Demi.woff2") format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
} */

/* @font-face {
	font-family: 'Avenir';
	src: url("../fonts/AvenirNextLTPro-Bold.woff2") format('woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
} */
