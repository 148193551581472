.root {
	--taptic-transition: transform 0.1s ease-out, color 0.15s ease-out, background 0.15s ease-out;

	composes: taptic from '~assets/styles/composes.css';
	display: flex;
	width: var(--selector-width, 100%);
	height: var(--selector-height, 56px);
	border: var(--selector-border, 0);
	border-radius: var(--selector-border-radius, 8px);
	margin: 0;
	padding: var(--selector-padding, var(--unit-x2));
	flex-direction: var(--selector-direction, column);
	align-items: center;
	justify-content: center;
	background: var(--selector-background, rgb(var(--color-panache)));
	color: var(--selector-color, inherit);
	box-shadow: var(--box-shadow, none);
	font-weight: 500;
	font-size: var(--selector-font-size, --text-l);
	line-height: 1.6;
	user-select: none;
}

.arrow {
	margin-left: 8px;
}

.label,
.value {
	pointer-events: none;
}

.label {
	color: var(--selector-label-color, #707070);
}

.value {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.selected {
	--box-shadow: var(--light-box-shadow);

	& .label {
		color: var(--selector-label-color, #707070);
		font-size: 12px;
	}

	& .value {
		color: var(--selector-value-color);
	}

	&.left {
		align-items: flex-start;
		text-align: left;
	}

	&.highlighted {
		background: #fff;
		box-shadow: var(--light-box-shadow);
	}
}

.invalid {
	box-shadow: inset 0 0 0 1px var(--error-color);
}

.active {
	--selector-label-color: #707070;
	--selector-value-color: rgb(var(--color-lunar));

	z-index: calc(var(--level-common) + 500);
	background: #fff;
}
