.root {
	display: flex;
	border-radius: var(--unit-x2);
	padding: 16px 16px 16px 16px;
	align-items: flex-start;
	overflow: hidden;

	@media (--tablet) {
		border-radius: var(--unit-x2);
		padding: 7px 12px;

		&:last-child {
			border-radius: var(--unit-x2);
		}
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: 500;
	font-size: var(--text-m);
	line-height: 1.6;

	&.withCloseButton {
		padding-right: 8px;
	}

	@media (--tablet) {
		flex-direction: row;

		&.withCloseButton {
			padding-right: 16px;
		}
	}
}

.message {
	padding: 7px 0;

	@media (--tablet) {
		padding: 7px 4px;
	}
}

.successIcon {
	display: flex;
	width: 22px;
	height: 22px;
	border: 1px solid rgb(var(--kit-colors-charcoal-800));
	border-radius: 50%;
	margin-top: 5px;
	margin-right: 15px;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
}

.successIcon svg {
	width: 10px;
	height: 10px;
}

.closeButton {
	display: block;
	width: 40px;
	height: 40px;
	border: 0;
	border-radius: 100%;
	margin: 0 0 0 auto;
	padding: 0;
	flex-shrink: 0;
	align-items: center;
	background-color: transparent;
	color: inherit;
	color: rgb(var(--color-lunar));
	appearance: none;

	&:focus {
		outline: none;
	}

	@media (--tablet) {
		width: 22px;
		height: 22px;
		margin: 7px 0 5px auto;
	}
}

.closeIcon {
	width: 18px;
	height: 18px;
	margin: auto;
}

.failed .closeIcon {
	color: rgb(var(--kit-colors-pure-800));
}

.actionLink {
	border-bottom: 1px currentColor solid;
	margin-top: 24px;
	margin-left: 48px;
	color: inherit;
	text-decoration: none;
	white-space: nowrap;

	@media (--tablet) {
		margin-top: 0;
		margin-left: 4px;
	}
}

.actionButton {
	box-sizing: border-box;
	border: 0;
	border-radius: 4px;
	margin: 24px 0 0 48px;
	padding: 14px 16px;
	font-weight: 700;
	font-size: 14px;
	line-height: 1;
	text-transform: uppercase;
	white-space: nowrap;
	appearance: none;

	&:focus {
		outline: none;
	}

	@media (--tablet) {
		margin: 0 0 0 8px;
	}
}

.success {
	background-color: rgb(var(--kit-colors-grass-200));
	color: rgb(var(--kit-colors-charcoal-800));

	& .actionButton {
		background-color: #fff;
		color: rgb(var(--color-sark));
	}
}

.failed {
	background-color: rgb(var(--color-lunar));
	color: rgb(var(--color-white));

	& .actionButton {
		background-color: #fff;
		color: rgb(var(--color-lunar));
	}
}

.info {
	background-color: rgb(var(--color-panache));
	color: rgb(var(--color-sark));

	& .actionButton {
		background-color: rgb(var(--color-sark));
		color: rgb(var(--color-white));
	}
}
