@import url(default.css);

@import url(mobile.css);

@import url(tablet.css);

@import url(largeTablet.css);

@import url(laptop.css);

@import url(desktop.css);

:root {
	--h1-font-size: 48px;
	--h1-font-weight: 700;
	--h1-line-height: 1;
	--h1-text-decoration: none;
	--h1-text-transform: none;
	--h1-letter-spacing: normal;
	--h2-font-size: 24px;
	--h2-font-weight: 400;
	--h2-line-height: 1.5;
	--h2-text-decoration: none;
	--h2-text-transform: none;
	--h2-letter-spacing: normal;
	--h3-font-size: 20px;
	--h3-font-weight: 400;
	--h3-line-height: 1.5;
	--h3-text-decoration: none;
	--h3-text-transform: none;
	--h3-letter-spacing: normal;
	--h4-font-size: 16px;
	--h4-font-weight: 500;
	--h4-line-height: 1.5;
	--h4-text-decoration: none;
	--h4-text-transform: none;
	--h4-letter-spacing: normal;
	--h5-font-size: 14px;
	--h5-font-weight: 500;
	--h5-line-height: 1.42;
	--h5-text-decoration: none;
	--h5-text-transform: none;
	--h5-letter-spacing: normal;
	--h6-font-size: 12px;
	--h6-font-weight: 700;
	--h6-line-height: 1.5;
	--h6-text-decoration: none;
	--h6-text-transform: uppercase;
	--h6-letter-spacing: 1px;
	--lead-font-size: 20px;
	--lead-font-weight: 300;
	--lead-line-height: 1.5;
	--lead-text-decoration: none;
	--lead-text-transform: none;
	--lead-letter-spacing: normal;
	--body-font-size: 14px;
	--body-font-weight: 300;
	--body-line-height: 1.42;
	--body-text-decoration: none;
	--body-text-transform: none;
	--body-letter-spacing: normal;
	--bold-font-size: 14px;
	--bold-font-weight: 600;
	--bold-line-height: 1.42;
	--bold-text-decoration: normal;
	--bold-text-transform: none;
	--bold-letter-spacing: normal;
	--underline-font-size: 14px;
	--underline-font-weight: 400;
	--underline-line-height: 1.42;
	--underline-text-decoration: underline;
	--underline-text-transform: none;
	--underline-letter-spacing: normal;
	--small-font-size: 12px;
	--small-font-weight: 400;
	--small-line-height: 1.42;
	--small-text-decoration: none;
	--small-text-transform: none;
	--small-letter-spacing: normal;
	--poi-h1-font-size: 44px;
	--poi-h1-font-weight: 700;
	--poi-h1-line-height: 1.09;
	--poi-h1-text-decoration: none;
	--poi-h1-text-transform: none;
	--poi-h1-letter-spacing: normal;
	--poi-h1-text-shadow: 0 0 8px rgba(var(--color-black), 0.4);
	--poi-h2-font-size: 36px;
	--poi-h2-font-weight: 400;
	--poi-h2-line-height: 1.28;
	--poi-h2-text-decoration: none;
	--poi-h2-text-transform: none;
	--poi-h2-letter-spacing: normal;
	--poi-h3-font-size: 28px;
	--poi-h3-font-weight: 400;
	--poi-h3-line-height: 1.29;
	--poi-h3-text-decoration: none;
	--poi-h3-text-transform: none;
	--poi-h3-letter-spacing: normal;

	@media (--large-tablet-down) {
		--poi-h1-font-size: 32px;
		--poi-h2-font-size: 28px;
		--poi-h3-font-size: 24px;
	}
}
