.overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--popup-z-overlay, var(--level-overlay));
	width: 100%;
	height: var(--select-overlay-height, 100vh);
	background: var(--select-popup-background, rgba(0, 0, 0, 0.4));
	transition: opacity 0.25s ease-in;
	backface-visibility: hidden;
	opacity: var(--select-popup-opacity, 1);
	will-change: opacity;

	@media (--tablet) {
		--select-popup-background: rgba(0, 0, 0, 0.2);
	}
}

.overlay.hidden {
	--select-popup-opacity: 0;
	pointer-events: none;
}

.popup {
	position: fixed;
	top: auto;
	right: 0;
	bottom: 0;
	left: auto;
	z-index: calc(var(--level-popup) + 3);
	box-sizing: border-box;
	width: 100%;
	height: var(--select-popup-height, initial);
	border-radius: var(--select-popup-border, 16px) var(--select-popup-border, 16px) 0 0;
	background: rgb(var(--color-white));
	box-shadow: var(--light-box-shadow);
	transition-timing-function: ease;
	transition-duration: 0.2s;
	/* temporary hot fix for safari glitch */
	/* transition-property: transform, opacity; */
	transition-property: opacity;
	transform: var(--select-popup-transform, translateY(0) scale(1));
	backface-visibility: hidden;
	opacity: var(--select-popup-opacity, 1);
	will-change: transform, opacity;

	@media (--laptop) {
		top: 100%;
		right: 0;
		bottom: auto;
		left: auto;
	}

	@media (--tablet) {
		position: absolute;
		width: var(--popup-width, 392px);
		border-radius: var(--popup-radius, 16px);
		transform: var(--select-popup-transform, translateY(16px) scale(1) rotateX(0));
		transform-origin: 0 0;
	}
}

.combodown {
	@media (--tablet) {
		top: 100%;
		right: 0;
		bottom: auto;
		left: auto;
	}

	@media (--laptop) {
		top: 100%;
		right: auto;
		bottom: auto;
		left: 0;
	}
}

.rightdown {
	@media (--tablet) {
		top: 100%;
		right: auto;
		bottom: auto;
		left: 0;
	}
}

.leftdown {
	@media (--tablet) {
		top: 100%;
		right: 0;
		bottom: auto;
		left: auto;
	}
}

.leftup {
	@media (--tablet) {
		top: auto;
		right: 0;
		bottom: 90px;
		left: auto;
	}
}

.leftdowncenter {
	@media (--tablet) {
		top: auto;
		right: 0px;
		bottom: 90px;
		left: auto;
	}

	@media (--laptop) {
		top: -180px;
		right: 172px;
		bottom: auto;
		left: auto;
	}
}

.leftcenter {
	@media (--tablet) {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translate(65%, 20%);
	}

	@media (--laptop) {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translate(100%, 30%);
	}
}

.center {
	@media (--tablet) {
		top: 50vh;
		right: auto;
		bottom: auto;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.screencenter {
	@media (--tablet) {
		position: fixed;
		top: calc(50vh + var(--header-height) / 2);
		right: auto;
		bottom: auto;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.leftupdown {
	@media (--laptop) {
		top: auto;
		right: 0;
		bottom: 0;
		left: auto;
		transform: translate(-45%, 15%);
	}

	@media (--tablet) {
		composes: leftup;
	}
}

.popup::-webkit-scrollbar {
	width: 5px;
	background: rgba(255, 255, 255, 0) repeat-y !important;
}

.popup::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.3);
}

.popup::-webkit-scrollbar-track {
	border-radius: 5px;
	background: rgba(255, 255, 255, 0) repeat-y !important;
}

.popup::-webkit-scrollbar-track-piece:start {
	background: rgba(255, 255, 255, 0) repeat-y !important;
}

.popup::-webkit-scrollbar-track-piece:end {
	background: rgba(255, 255, 255, 0) repeat-y !important;
}

.popup.hidden {
	--select-popup-transform: translateY(100%) scale(0.9);

	@media (--tablet) {
		--select-popup-opacity: 0;
		--select-popup-transform: translateY(16px) scale(0.95) rotateX(-15deg);
	}
}
