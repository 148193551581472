/* stylelint-disable */
.cobalt {
	color: rgb(var(--kit-colors-cobalt));
}

.cobalt-100 {
	color: rgb(var(--kit-colors-cobalt-100));
}

.cobalt-200 {
	color: rgb(var(--kit-colors-cobalt-200));
}

.cobalt-400 {
	color: rgb(var(--kit-colors-cobalt-400));
}

.cobalt-600 {
	color: rgb(var(--kit-colors-cobalt-600));
}

.cobalt-800 {
	color: rgb(var(--kit-colors-cobalt-800));
}

.citron {
	color: rgb(var(--kit-colors-citron));
}

.citron-100 {
	color: rgb(var(--kit-colors-citron-100));
}

.citron-200 {
	color: rgb(var(--kit-colors-citron-200));
}

.citron-400 {
	color: rgb(var(--kit-colors-citron-400));
}

.citron-600 {
	color: rgb(var(--kit-colors-citron-600));
}

.citron-800 {
	color: rgb(var(--kit-colors-citron-800));
}

.tangerine {
	color: rgb(var(--kit-colors-tangerine));
}

.tangerine-100 {
	color: rgb(var(--kit-colors-tangerine-100));
}

.tangerine-200 {
	color: rgb(var(--kit-colors-tangerine-200));
}

.tangerine-400 {
	color: rgb(var(--kit-colors-tangerine-400));
}

.tangerine-600 {
	color: rgb(var(--kit-colors-tangerine-600));
}

.tangerine-800 {
	color: rgb(var(--kit-colors-tangerine-800));
}

.grass {
	color: rgb(var(--kit-colors-grass));
}

.grass-100 {
	color: rgb(var(--kit-colors-grass-100));
}

.grass-200 {
	color: rgb(var(--kit-colors-grass-200));
}

.grass-400 {
	color: rgb(var(--kit-colors-grass-400));
}

.grass-600 {
	color: rgb(var(--kit-colors-grass-600));
}

.grass-800 {
	color: rgb(var(--kit-colors-grass-800));
}

.orchid {
	color: rgb(var(--kit-colors-orchid));
}

.orchid-100 {
	color: rgb(var(--kit-colors-orchid-100));
}

.orchid-200 {
	color: rgb(var(--kit-colors-orchid-200));
}

.orchid-400 {
	color: rgb(var(--kit-colors-orchid-400));
}

.orchid-600 {
	color: rgb(var(--kit-colors-orchid-600));
}

.orchid-800 {
	color: rgb(var(--kit-colors-orchid-800));
}

.charcoal {
	color: rgb(var(--kit-colors-charcoal));
}

.charcoal-100 {
	color: rgb(var(--kit-colors-charcoal-100));
}

.charcoal-200 {
	color: rgb(var(--kit-colors-charcoal-200));
}

.charcoal-400 {
	color: rgb(var(--kit-colors-charcoal-400));
}

.charcoal-600 {
	color: rgb(var(--kit-colors-charcoal-600));
}

.charcoal-800 {
	color: rgb(var(--kit-colors-charcoal-800));
}

.pure {
	color: rgb(var(--kit-colors-pure));
}

.pure-100 {
	color: rgb(var(--kit-colors-pure-100));
}

.pure-200 {
	color: rgb(var(--kit-colors-pure-200));
}

.pure-400 {
	color: rgb(var(--kit-colors-pure-400));
}

.pure-600 {
	color: rgb(var(--kit-colors-pure-600));
}
.pure-700 {
	color: rgb(var(--kit-colors-pure-700));
}
.pure-800 {
	color: rgb(var(--kit-colors-pure-800));
}

.danger {
	color: rgb(var(--kit-colors-danger));
}
.danger-100 {
	color: rgb(var(--kit-colors-danger-100));
}
.danger-200 {
	color: rgb(var(--kit-colors-danger-200));
}
.danger-400 {
	color: rgb(var(--kit-colors-danger-400));
}
.danger-600 {
	color: rgb(var(--kit-colors-danger-600));
}
.danger-800 {
	color: rgb(var(--kit-colors-danger-800));
}

.night {
	color: rgb(var(--kit-colors-night));
}
