.root {
	margin-bottom: 24px;
	transform: var(--select-popup-transform, translateY(24px));
	backface-visibility: hidden;
	will-change: transform;

	@supports (margin-bottom: env(safe-area-inset-bottom)) {
		margin-bottom: calc(env(safe-area-inset-bottom) + 24px);
	}
}
