.root {
	position: relative;
	display: flex;
	width: 100%;
	height: var(--header-height);
	border-bottom: 1px solid rgb(var(--color-black-25));
	background: #fff;
	user-select: none;

	@media (--tablet) {
		border-bottom: none;
		background: transparent;
	}

	@media (--laptop) {
		font-weight: 500;
		font-size: var(--text-ssm);
	}
}

.nav {
	display: flex;
	width: 100%;

	align-items: center;
	justify-content: space-between;
}

.light {
	color: rgb(var(--color-black));

	@media (--tablet) {
		color: rgb(var(--color-white));
	}
}

.dark {
	color: rgb(var(--color-black));
}

.cobalt {
	background: rgb(var(--kit-colors-cobalt-800));
	color: rgb(var(--color-white));
}
