#nprogress {
	position: relative;
	z-index: calc(var(--level-popup) + 2);
	pointer-events: none;
}

#nprogress .bar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--layer01);
	width: 100%;
	height: 2px;
	background: rgb(var(--color-sark));
}

#nprogress .peg {
	position: absolute;
	right: 0;
	display: block;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #fff, 0 0 5px #fff;
	transform: rotate(3deg) translate(0, -4px);
	opacity: 1;
}
