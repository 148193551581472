.taptic {
	transition: var(--taptic-transition, transform 0.1s ease-out);
	transform: scale(1);
	-webkit-tap-highlight-color: var(--taptic-color, rgba(var(--color-sark), 0.1));

	&:active {
		transform: scale(var(--taptic-scale, 0.96));
	}

	&:focus {
		--taptic-scale: 1;
	}
}

.tapticDisable {
	transition: none;
	-webkit-tap-highlight-color: transparent;

	&:active {
		transform: none;
	}
}

.resetButton,
.resetInput {
	border: 0;
	margin: 0;
	padding: 0;
	appearance: none;

	&:focus {
		outline: none;
	}
}

.resetList {
	margin: 0;
	padding: 0;
	list-style: none;
}

.shadowBox {
	border-radius: 8px;
	background-color: #fff;
	box-shadow: var(--light-box-shadow);
}

.pill {
	composes: shadowBox;
	height: 44px;
	border-radius: 22px;
	line-height: 44px;
	white-space: nowrap;
}

.nobr {
	white-space: nowrap;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.h5 {
	font-weight: bold;
	font-size: 32px;
	line-height: 110%;
}

.scrollBar {
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background: rgba(255, 255, 255, 0) repeat-y !important;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background: rgba(0, 0, 0, 0.3);
		opacity: 1;
	}

	&::-webkit-scrollbar-track {
		border-radius: 5px;
		background: rgba(255, 255, 255, 0) repeat-y !important;
		opacity: 1;
	}

	&::-webkit-scrollbar-track-piece:start {
		background: rgba(255, 255, 255, 0) repeat-y !important;
		opacity: 1;
	}

	&::-webkit-scrollbar-track-piece:end {
		background: rgba(255, 255, 255, 0) repeat-y !important;
		opacity: 1;
	}
}
