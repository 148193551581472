.logo {
	position: relative;
	width: 54px;
	height: 18px;

	@media (--tablet) {
		height: 21px;
	}
}

.icon {
	display: flex;
	width: 48px;
	height: 48px;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;

	@media (--tablet) {
		display: none;
	}
}

/* Menu items order */

.logoItem {
	position: absolute;
	right: 0;
	left: 0;
	width: var(--header-logo-width);
	margin-right: auto;
	margin-left: auto;
	text-align: center;

	@media (--tablet) {
		right: 0;
	}
}

.logoItemLogged {
	right: 25%;

	@media (--tablet) {
		right: 0;
	}
}

.allhomesItem,
.userItem,
.ownersItem,
.buttonDesktop {
	display: none;

	@media (--tablet) {
		display: block;
	}
}

.buttonLaptop {
	display: none;

	@media (--laptop) {
		display: block;
	}
}

.reservationsItem {
	--link-vertical-padding: 0px;

	@media (--tablet) {
		margin-right: 10px;
	}
}

.signupItem,
.signinItem {
	--link-vertical-padding: 0px;
	--button-width: 130px;
	--button-height: 32px;
	--button-tt: none;
	--button-fw: 500;
}

.signupItem {
	display: none;

	@media (--tablet) {
		display: block;
	}
}

.signinItem {
	--button-border-color: rgb(var(--color-black-25));
	display: block;
}

.signinItem .buttonLaptop {
	--button-width: auto;
}

/* Dropdown list */

.list {
	min-width: 160px;
	border-radius: var(--radius-m);
	margin: 0;
	margin-top: 8px;
	padding: 8px 16px;
	overflow: hidden;
	background-color: #fff;
	box-shadow: var(--light-box-shadow);
	list-style: none;
	will-change: transform;

	@media (--tablet) {
		min-width: 148px;
	}
}

.listMobile {
	--arrow-round-bg: rgba(var(--color-sark), 0.04);
	--arrow-color: rgb(var(--color-lunar-50));
	margin: 0;
	margin-top: 0;
	padding-top: 15px;
	padding-right: 15px;
	padding-bottom: 40px;
	padding-left: 15px;
	overflow: hidden;
	background-color: #fff;
	list-style: none;
	font-size: var(--text-xl);
	will-change: transform;
}

.listUser {
	box-sizing: border-box;
	border-radius: var(--radius-l);

	@media (--tablet) {
		min-width: 360px;
	}
}

.item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	font-size: var(--text-ssm);
	line-height: 28px;

	& > a {
		width: 100%;
		text-decoration: none;
	}
}

.item + .item {
	border-top: 1px solid rgb(var(--color-black-10));
}

.itemMobile {
	display: flex;
	padding: 19px 0;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	font-size: var(--text-xl);

	& > div:last-child {
		width: 18px;
	}
}

.itemMobile + .itemMobile {
	border-top: 1px solid rgb(var(--color-black-10));
}

.link {
	display: flex;
	width: 100%;
	align-items: center;
	text-decoration: none;
	white-space: nowrap;
}

.disabledLink {
	color: rgb(var(--color-black-25));
	pointer-events: none;
}

/* User item */

.user {
	--arrow-color: rgb(var(--color-black));
	display: flex;
	height: 22px;
	border: 1px solid rgb(var(--color-black-25));
	border-radius: 21px;
	padding: 4px;
	flex-direction: row;
	align-items: center;
	color: rgb(var(--color-lunar));
	box-shadow: var(--light-box-shadow);
}

.userPhoto {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	margin: 12px 0;
	flex-shrink: 0;
	overflow: hidden;
	pointer-events: none;

	@media (--tablet) {
		width: 22px;
		height: 22px;
		margin-top: 0;
		margin-right: 8px;
		margin-bottom: 0;
	}
}

.userName {
	display: block;
	max-width: 60px;
	overflow: hidden;
	color: rgb(var(--color-black));
	font-weight: 400;
	font-size: var(--text-s);
	text-overflow: ellipsis;
	pointer-events: none;
}

.spacer {
	margin: 0 auto;
}

.userSignIn {
	display: block;
	margin-right: 10px;

	@media (--tablet) {
		display: none;
	}
}

.userSignIn .item {
	min-width: 190px;
	font-size: var(--text-l);
	line-height: 42px;
}

.mobileUserItem {
	composes: user;
	min-width: 24px;

	@media (--tablet) {
		display: none;
	}
}

.location {
	display: block;

	@media (--tablet) {
		display: none;
	}
}

.icomMobile {
	display: flex;
	box-sizing: border-box;
	width: var(--header-height);
	height: var(--header-height);
	padding: 11px;
	padding-right: 8px;
	padding-left: 14px;
	align-items: center;
	justify-content: center;
	color: rgb(var(--color-black));
	cursor: pointer;
}

.locationIcon {
	composes: icomMobile;
	padding-right: 0;
	padding-left: 0;

	@media (--tablet) {
		width: 28px; /* fix layout shifting */
		padding-right: 8px;
	}
}

.crossIcon {
	composes: icomMobile;
}

.userIcon {
	composes: icomMobile;
	width: 16px;
	height: 16px;
	margin-left: 5px;
	padding: 0px;
}

.mobileUserIcon {
	composes: userIcon;
	margin: 8px;
}

.summary {
	display: flex;
	align-items: center;
	justify-content: space-between;
	outline: none;
	list-style: none;
	cursor: pointer;
}

.summaryLink {
	text-decoration: none;
}

.arrow {
	--arrow-round-bg: rgba(var(--color-sark), 0.04);
	--arrow-color: rgb(var(--color-lunar-50));
	width: 24px;
	height: 24px;
	margin-left: 2px;
	margin-left: auto;
	flex-shrink: 0;
	pointer-events: none;
}

.list .arrow {
	width: 12px;
	transform: rotate(270deg);
}

.summaryLink .arrow {
	transform: rotate(270deg);
}

.details[open] .arrow {
	transform: rotate(180deg);
}

.summary::-webkit-details-marker,
.summary::marker {
	display: none;
}

.details {
	border-bottom: 1px solid rgb(var(--color-black-10));
	margin: 12px 0;
	margin-top: 20px;
	padding-bottom: 10px;
}

.details > a {
	text-decoration: none;
}

.userArrow {
	composes: arrow;

	margin-right: 2px;
}

.listMobileTitle {
	font-weight: 500;
	font-size: var(--text-xl);
}

.listMobileSubTitle {
	padding-top: 7px;
	font-size: var(--text-ssm);
}

.blockList {
	display: flex;
	padding: 3px 0 0px;
	flex-direction: column;
	flex-wrap: wrap;
	list-style: none;
	font-size: var(--text-m);
}

.blockListItem {
	display: flex;
	margin-right: 20px;
	margin-left: 27px;
}

.blockListItemLink {
	display: flex;
	width: 100%;
	padding: 14px 0 14px 0px;
	font-weight: 500;
	font-size: var(--text-xl);
	text-decoration: none;
}

.blockListItem + .blockListItem {
	border-top: 1px solid rgb(var(--color-black-10));
}

.cities {
	display: flex;
	align-items: center;
}

.citiesUpper {
	text-transform: uppercase;
}

.cityList {
	padding: 20px 0;
	padding-bottom: 10px;
}

.cityListItemLink {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: flex-end;
	text-align: left;
	text-decoration: none;
}

.cityListItemSlug,
.cityListItemCount,
.detailsContent {
	pointer-events: none;
}

.cityListItem {
	box-sizing: border-box;
	width: calc(50% - 12px);
	height: 240px;
	border: 1px solid #e8e8e8;
	margin: 6px;
	padding: 6px;

	text-align: left;
}

.desktopCities {
	display: none;
	box-sizing: border-box;
	padding: 28px 25px;
	padding-top: 25px;
	background: #fff;
	color: rgb(var(--color-black));

	@media (--tablet) {
		display: block;
	}
}

.headerCities {
	padding-bottom: 22px;
	font-weight: 500;
	font-size: 21px;
}

.reservations {
	background-color: rgb(var(--color-white));
}

.reservationsButton {
	position: relative;
	display: flex;
	border: 1px solid rgb(var(--kit-colors-pure-200));
	border-radius: 200px;
	padding: 7px 14px;
	gap: 8px;
	align-items: center;

	&.notification {
		&:after {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			content: '';
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: rgb(var(--kit-colors-tangerine-800));

			@media (--tablet) {
				width: 10px;
				height: 10px;
			}
		}
	}

	@media (--tablet) {
		padding: 6px 16px;
	}
}

.scheduleIcon {
	width: 16px;
	height: 16px;
}

.favorites {
	composes: user;
}

.unauthorizedFavorites {
	@media (--tablet) {
		display: inline-flex;
		align-items: center;
	}
}

.favoritesIcon {
	display: flex;
	padding: 8px;

	@media (--tablet) {
		display: none;
	}
}

/* TODO: rework header buttons */

.unauthorizedFavoritesButton {
	width: 66px;

	@media (--tablet) {
		width: unset;
	}
}

.unauthorizedFavorites .favoritesIcon {
	padding: 0 8px;
}

.favoritesIcon > svg {
	width: 18px;
	height: 18px;
	color: rgb(var(--kit-colors-charcoal-800));
	fill: rgb(var(--kit-colors-charcoal-800));
}

.favoritesTitle {
	display: none;
	margin: 0 12px;
	color: rgb(var(--color-black));
	pointer-events: none;

	@media (--tablet) {
		display: block;
	}
}

.notVisible {
	display: none;
}
