.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.lead,
.body,
.bold,
.underline,
.small,
.poiH1,
.poiH2,
.poiH3 {
	font-style: normal;
	font-family: var(--kit-font-primary);
	letter-spacing: normal;
}

.h1 {
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	line-height: var(--h1-line-height);
	letter-spacing: var(--h1-letter-spacing);
	text-decoration: var(--h1-text-decoration);
	text-transform: var(--h1-text-transform);
}

.h2 {
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	line-height: var(--h2-line-height);
	letter-spacing: var(--h2-letter-spacing);
	text-decoration: var(--h2-text-decoration);
	text-transform: var(--h2-text-transform);
}

.h3 {
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	line-height: var(--h3-line-height);
	letter-spacing: var(--h3-letter-spacing);
	text-decoration: var(--h3-text-decoration);
	text-transform: var(--h3-text-transform);
}

.h4 {
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	line-height: var(--h4-line-height);
	letter-spacing: var(--h4-letter-spacing);
	text-decoration: var(--h4-text-decoration);
	text-transform: var(--h4-text-transform);
}

.h5 {
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	line-height: var(--h5-line-height);
	letter-spacing: var(--h5-letter-spacing);
	text-decoration: var(--h5-text-decoration);
	text-transform: var(--h5-text-transform);
}

.h6 {
	font-weight: var(--h6-font-weight);
	font-size: var(--h6-font-size);
	line-height: var(--h6-line-height);
	letter-spacing: var(--h6-letter-spacing);
	text-decoration: var(--h6-text-decoration);
	text-transform: var(--h6-text-transform);
}

.lead {
	font-weight: var(--lead-font-weight);
	font-size: var(--lead-font-size);
	line-height: var(--lead-line-height);
	letter-spacing: var(--lead-letter-spacing);
	text-decoration: var(--lead-text-decoration);
	text-transform: var(--lead-text-transform);
}

.body {
	font-weight: var(--body-font-weight);
	font-size: var(--body-font-size);
	line-height: var(--body-line-height);
	letter-spacing: var(--body-letter-spacing);
	text-decoration: var(--body-text-decoration);
	text-transform: var(--body-text-transform);
}

.bold {
	font-weight: var(--bold-font-weight);
	font-size: var(--bold-font-size);
	line-height: var(--bold-line-height);
	letter-spacing: var(--bold-letter-spacing);
	text-decoration: var(--bold-text-decoration);
	text-transform: var(--bold-text-transform);
}

.underline {
	font-weight: var(--underline-font-weight);
	font-size: var(--underline-font-size);
	line-height: var(--underline-line-height);
	letter-spacing: var(--underline-letter-spacing);
	text-decoration: var(--underline-text-decoration);
	text-transform: var(--underline-text-transform);
}

.small {
	font-weight: var(--small-font-weight);
	font-size: var(--small-font-size);
	line-height: var(--small-line-height);
	letter-spacing: var(--small-letter-spacing);
	text-decoration: var(--small-text-decoration);
	text-transform: var(--small-text-transform);
}

.poiH1 {
	font-weight: var(--poi-h1-font-weight);
	font-size: var(--poi-h1-font-size);
	line-height: var(--poi-h1-line-height);
	letter-spacing: var(--poi-h1-letter-spacing);
	text-decoration: var(--poi-h1-text-decoration);
	text-transform: var(--poi-h1-text-transform);
	text-shadow: var(--poi-h1-text-shadow);
}

.poiH2 {
	font-weight: var(--poi-h2-font-weight);
	font-size: var(--poi-h2-font-size);
	line-height: var(--poi-h2-line-height);
	letter-spacing: var(--poi-h2-letter-spacing);
	text-decoration: var(--poi-h2-text-decoration);
	text-transform: var(--poi-h2-text-transform);
}

.poiH3 {
	font-weight: var(--poi-h3-font-weight);
	font-size: var(--poi-h3-font-size);
	line-height: var(--poi-h3-line-height);
	letter-spacing: var(--poi-h3-letter-spacing);
	text-decoration: var(--poi-h3-text-decoration);
	text-transform: var(--poi-h3-text-transform);
}
