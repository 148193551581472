.root {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
}

.loading {
	position: relative;
	pointer-events: none;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: var(--level-common);
		content: '';
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.4);
	}
}

.wrapper {
	height: 100%;
	min-height: 50px;
	padding: 10px 0;

	overflow: scroll;
	overflow-x: hidden;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}

.sections {
	display: flex;
	flex-direction: column;
	row-gap: 4px;
}

.list,
.item {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.list {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
}

.item {
	width: 100%;
	min-width: 280px;
}
