.root {
	display: none;
	box-sizing: border-box;
	width: 100%;
	padding: 0;
	overflow: hidden;

	@media (--tablet) {
		width: calc(100% + (var(--horizontal-offset) * 2));
		margin-left: calc(var(--unit-x4) * -1);
		padding: 0 var(--horizontal-offset);
	}
}

.show {
	display: block;
	animation: show-banner 300ms linear;
}

.hide {
	display: block;
	animation: hide-banner 300ms linear;
}

@keyframes show-banner {
	from {
		height: 0;
	}

	to {
		height: 40px;
	}
}

@keyframes hide-banner {
	from {
		height: 40px;
	}

	to {
		height: 0;
	}
}
