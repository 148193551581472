.root {
	display: flex;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: var(--arrow-background, unset);
	color: var(--arrow-color, inherit);

	transform: rotate(var(--icon-rotate));

	& > svg {
		width: var(--icon-size, 18px);
		height: var(--icon-size, 18px);
		margin: auto;
	}
}
