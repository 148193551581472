.root {
	position: relative;
	display: flex;
	height: 100%;
	max-height: 100%;
	flex-direction: column;
}

.header {
	padding: 24px 8px 0;
	text-align: center;

	@media (--tablet) {
		padding-right: 16px;
		padding-left: 16px;
	}
}

.subtitle {
	margin-top: 4px;
	color: rgb(var(--color-black-50));
	font-size: var(--text-s);
}

.scrollContainer {
	position: relative;
	z-index: var(--level-common);
	max-height: 70vh;
	overflow-y: auto;

	&:before,
	&:after {
		position: sticky;
		left: 0;
		z-index: 10;
		display: block;
		content: '';
		width: 100%;
		height: 20px;
	}

	&:before {
		top: 0;
		margin-top: 20px;
		margin-bottom: -20px;
		background: linear-gradient(0deg, rgba(255, 255, 255, 0), white);
	}

	&:after {
		bottom: 0;
		margin-top: -20px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0), white);
	}

	@media (--tablet) {
		max-height: var(--scroll-container-tablet, 60vh);
	}
}

.content {
	height: 100%;
	padding: var(--mobile-frame-padding, 8px);

	@media (--tablet) {
		padding: var(--frame-padding, 16px);
	}
}
