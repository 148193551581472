.root {
	position: relative;
	z-index: 1;
	display: inline-flex;
	box-sizing: border-box;
	width: 100%;
	height: var(--select-box-height, auto);
	border-radius: var(--select-box-radius, var(--radius-m));
	border-radius: 8px;
	overflow: hidden;
	background-color: var(--select-box-background, rgb(var(--color-panache)));
	user-select: none;
	transition: transform 0.1s ease;
	/* BEGIN: Fix rounded corners in Safari */
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	/* END: Fix rounded corners in Safari */
}

.input,
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	border: 0;
	margin: 0;
	padding: 0;
	opacity: 0;
}

.input {
	z-index: 1;
	cursor: pointer;
}

.input:disabled {
	cursor: not-allowed;
}

.checkmark {
	position: relative;
	display: block;
	display: flex;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	border: 1px solid var(--select-box-border-color, rgba(var(--color-sark), 0.1));
	border-radius: var(--radius-m);
	padding: var(--select-box-padding, 16px);
	align-items: center;
	justify-content: var(--select-box-justify, center);
	overflow: hidden;
	color: var(--select-box-color, rgb(var(--color-sark)));
	font-weight: var(--select-box-font-weight, 500);
	font-size: var(--select-box-fs, var(--text-l));
	text-align: var(--select-box-text-align, center);
	white-space: var(--select-box-checkmark-ws, nowrap);
	cursor: pointer;
	transition: background-color 0.2s ease-in, color 0.2s ease-in, transform 0.1s ease-in;
	/* BEGIN: Fix rounded corners in Safari */
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	/* END: Fix rounded corners in Safari */
	opacity: 1;

	&::after {
		position: absolute;
		top: 50%;
		left: -5px;
		z-index: -1;
		content: '';
		width: calc(100% + 10px);
		height: 0;
		border-radius: 50%;
		padding: 50% 0;
		background: var(--select-box-active-background, rgb(var(--color-sark)));
		transition: transform 0.3s ease, opacity 0.3s ease;
		transform: translate3d(0, -50%, 0) scale(0);
		backface-visibility: hidden;
		opacity: 0;
	}

	&:active {
		transform: scale(0.96);
	}
}

.input:disabled ~ .checkmark {
	color: rgb(var(--color-lunar-25));
}

.input:checked ~ .checkmark {
	border: 1px solid var(--select-box-active-border-color, rgba(var(--color-sark), 0.1));
	color: var(--select-box-active-color, rgb(255, 255, 255));
}

.input:checked ~ .checkmark:active {
	cursor: default;
	transform: scale(1);
}

.input:checked ~ .checkmark::after {
	transform: translate3d(0, -50%, 0) scale(1.5);
	opacity: 1;
}
