/* stylelint-disable */
.h1-700,
.h1-600,
.h2-700,
.h2-600,
.h2-serif,
.h3-700,
.h3-600,
.h3-500,
.h3-serif,
.h4-700,
.h4-600,
.h4-500,
.h4-serif,
.h5-700,
.h5-600,
.h5-500,
.h6-700,
.p1-400a,
.p1-400b,
.p1-500,
.p1-600,
.p1-700,
.blockquote,
.p2-400,
.p2-500,
.display-1,
.poi-h1,
.poi-h2,
.poi-h2 {
	font-style: normal;
	font-family: var(--kit-font-primary);
	letter-spacing: normal;
}

.h1-700 {
	font-size: 39px;
	font-weight: 700;
	line-height: 1.15;
	letter-spacing: -0.2px;

	@media (--tablet) {
		font-size: 48px;
		line-height: 1.2;
		letter-spacing: normal;
	}
}

.h1-600 {
	font-size: 39px;
	font-weight: 600;
	line-height: 1.3;
	letter-spacing: -0.2px;

	@media (--tablet) {
		font-size: 48px;
		line-height: 1.2;
		letter-spacing: normal;
	}
}

.h2-700 {
	font-size: 32px;
	font-weight: 700;
	line-height: 1.3;
	letter-spacing: -0.15px;

	@media (--tablet) {
		font-size: 34px;
		line-height: 1.35;
		letter-spacing: normal;
	}
}

.h2-600 {
	font-size: 32px;
	font-weight: 600;
	line-height: 1.3;
	letter-spacing: -0.15px;

	@media (--tablet) {
		font-size: 34px;
		line-height: 1.35;
		letter-spacing: normal;
	}
}

.h2-serif {
	font-size: 32px;
	font-weight: 600;
	line-height: 1.3;
	letter-spacing: -0.15px;

	@media (--tablet) {
		font-family: var(--font-serif);
		font-size: 34px;
		font-weight: 400;
		line-height: 1.35;
		letter-spacing: -0.2px;
	}
}

.h3-700 {
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;

	@media (--tablet) {
		font-size: 24px;
		line-height: 1.45;
	}
}

.h3-600 {
	font-size: 24px;
	font-weight: 600;
	line-height: 1.3;

	@media (--tablet) {
		font-size: 24px;
		line-height: 1.45;
	}
}

.h3-500 {
	font-size: 24px;
	font-weight: 600;
	line-height: 1.3;

	@media (--tablet) {
		font-size: 24px;
		font-weight: 500;
		line-height: 1.45;
	}
}

.h3-serif {
	font-family: var(--font-serif);
	font-size: 24px;
	font-weight: 400;
	line-height: 1.3;

	@media (--tablet) {
		font-size: 24px;
		line-height: 1.45;
	}
}

.h4-700 {
	font-size: 20px;
	font-weight: 700;
	line-height: 1.48;

	@media (--tablet) {
		font-size: 20px;
		line-height: 1.55;
	}
}

.h4-600 {
	font-size: 20px;
	font-weight: 600;
	line-height: 1.48;
	letter-spacing: -0.1px;

	@media (--tablet) {
		font-size: 20px;
		line-height: 1.55;
		letter-spacing: normal;
	}
}

.h4-500 {
	font-size: 20px;
	font-weight: 600;
	line-height: 1.48;
	letter-spacing: -0.1px;

	@media (--tablet) {
		font-weight: 500;
		line-height: 1.55;
		letter-spacing: normal;
	}
}

.h4-serif {
	font-size: 20px;
	font-weight: 600;
	line-height: 1.48;
	letter-spacing: -0.1px;

	@media (--tablet) {
		font-family: var(--font-serif);
		font-weight: 400;
		line-height: 1.55;
		letter-spacing: -0.1px;
	}
}

.h5-700 {
	font-size: 17px;
	font-weight: 700;
	line-height: 1.45;

	@media (--tablet) {
		font-size: 16px;
		line-height: 1.55;
	}
}

.h5-600 {
	font-size: 17px;
	font-weight: 600;
	line-height: 1.45;

	@media (--tablet) {
		font-size: 16px;
		line-height: 1.55;
	}
}

.h5-500 {
	font-size: 17px;
	font-weight: 500;
	line-height: 1.45;

	@media (--tablet) {
		font-size: 16px;
		line-height: 1.55;
	}
}

.h6-700 {
	font-size: 11px;
	font-weight: 700;
	line-height: 1.35;

	@media (--tablet) {
		font-size: 12px;
		line-height: 1.5;
		letter-spacing: 1px;
	}
}

.p1-400a {
	font-size: 15px;
	font-weight: 400;
	line-height: 1.55;
	letter-spacing: 0.05px;

	@media (--tablet) {
		font-size: 14px;
		line-height: 1.57;
		letter-spacing: normal;
	}
}

.p1-400b {
	font-size: 15px;
	font-weight: 400;
	line-height: 1.8;
	letter-spacing: 0.05px;

	@media (--tablet) {
		font-size: 14px;
		line-height: 1.86;
		letter-spacing: normal;
	}
}

.p1-500 {
	font-size: 15px;
	font-weight: 500;
	line-height: 1.55;
	letter-spacing: 0.05px;

	@media (--tablet) {
		font-size: 14px;
		line-height: 1.57;
		letter-spacing: normal;
	}
}

.p1-600 {
	font-size: 15px;
	font-weight: 600;
	line-height: 1.55;
	letter-spacing: 0.05px;

	@media (--tablet) {
		font-size: 14px;
		line-height: 1.57;
		letter-spacing: normal;
	}
}

.p1-700 {
	font-size: 15px;
	font-weight: 700;
	line-height: 1.55;
	letter-spacing: 0.05px;

	@media (--tablet) {
		font-size: 14px;
		line-height: 1.57;
		letter-spacing: normal;
	}
}

.blockquote {
	font-family: var(--font-serif);
	font-size: 15px;
	font-weight: 400;
	line-height: 1.55;
	letter-spacing: -0.1px;

	@media (--tablet) {
		font-size: 14px;
		line-height: 1.57;
		letter-spacing: normal;
	}
}

.p2-400 {
	font-size: 13px;
	font-weight: 400;
	line-height: 1.55;

	@media (--tablet) {
		font-size: 12px;
		line-height: 1.57;
	}
}

.p2-500 {
	font-size: 13px;
	font-weight: 500;
	line-height: 1.55;

	@media (--tablet) {
		font-size: 12px;
		line-height: 1.57;
	}
}

.display-1 {
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;

	@media (--tablet) {
		font-size: 58px;
		font-weight: 400;
		line-height: 1;
	}
}

.poi-h1 {
	font-size: 32px;
	font-weight: 700;
	line-height: 125%;

	@media (--tablet) {
		font-size: 44px;
		line-height: 1.1;
	}
}

.poi-h2 {
	font-size: 28px;
	font-weight: 600;
	line-height: 130%;

	@media (--tablet) {
		font-size: 36px;
		line-height: 1.33;
	}
}

.poi-h3 {
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;

	@media (--tablet) {
		font-size: 28px;
		line-height: 1.3;
	}
}
