.root {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	pointer-events: none;

	@media (--tablet) {
		width: 180px;
		height: 180px;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 8px;
			box-shadow: inset 0 0 0 1px rgb(var(--kit-colors-pure-400));
		}
	}
}

.link {
	text-decoration: none;
}

.grayscale {
	filter: grayscale(1);
	pointer-events: none;
}

.content {
	display: flex;
	padding: 10px;
	flex-direction: column;
	color: rgb(var(--kit-colors-charcoal-800));
}

.title {
	font-weight: 600;
	font-size: var(--title-h3);

	@media (--tablet) {
		font-size: var(--title-h5);
	}
}

.description {
	color: rgb(var(--kit-colors-charcoal-100));
	font-size: var(--text-l);

	@media (--tablet) {
		font-size: var(--title-m);
	}
}

.logo {
	display: flex;
	width: 90px;
	align-items: center;
	justify-content: center;

	@media (--tablet) {
		width: 100px;
		height: 80px;
		align-items: end;
	}
}
