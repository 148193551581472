.root {
	@media (--tablet) {
		display: none;
	}
}

.burgerMenu {
	position: fixed;
	left: 0;
	z-index: calc(var(--level-overlay) + 1);
	width: 100%;
	height: calc(100% - var(--header-height));
	height: calc(100% - var(--header-height) + env(safe-area-inset-bottom));
	border-top: 1px solid rgb(var(--color-black-25));
	overflow-x: auto;
	background: #fff;
}

.burgerMenuItem {
	display: block;
	width: 100%;
	height: 100px;
}
