.contacts {
	display: flex;
	flex-direction: column;
	gap: 24px;
	color: rgb(var(--kit-colors-charcoal-800));
}

.listReset {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.contactsList {
	composes: listReset;
	display: flex;
	flex-direction: column;
}

.link {
	&:hover {
		color: inherit;
	}
}

.socialList {
	composes: listReset;

	display: flex;
	height: 25px;

	& > * + * {
		margin-left: var(--unit-x4);
	}
}
