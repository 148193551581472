.root {
	position: relative;
	display: flex;
	box-sizing: border-box;
	height: 100%;
	min-height: 100%;
	margin: 0 auto;
	flex-direction: column;
}

.withHeader {
	padding-bottom: 0;
}

.header {
	position: relative;
	bottom: auto;
	z-index: calc(var(--level-popup) + 1);
	margin: 0px;
	background: rgb(var(--color-white));

	@media (--tablet) {
		position: relative;
		padding-right: var(--unit-x4);
		padding-left: var(--unit-x4);
	}
}

.withOffset {
	padding-right: var(--horizontal-offset);
	padding-left: var(--horizontal-offset);
}

.withOffset .header {
	margin-right: calc(var(--horizontal-offset) * -1);
	margin-left: calc(var(--horizontal-offset) * -1);

	@media (--tablet) {
		padding-right: var(--unit-x4);
		padding-left: var(--unit-x4);
	}
}

.stickyHeader {
	@media (--tablet) {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
	}
}

.backButton {
	position: fixed;
	top: 24px;
	left: var(--unit-x2);
	z-index: var(--layer02);
	display: none;

	@media (--tablet) {
		top: 32px;
		left: 32px;
	}

	@media (--laptop) {
		top: 60px;
		left: 60px;
	}
}

.showMobile {
	display: block;

	@media (--tablet) {
		display: none;
	}
}

.showDesktop {
	display: none;

	@media (--tablet) {
		display: block;
	}
}

.showAll {
	display: block;
}

.logo {
	display: block;
	width: 136px;
}

.withOffset .footer {
	margin-right: calc(-1 * var(--horizontal-offset));
	margin-left: calc(-1 * var(--horizontal-offset));
}
