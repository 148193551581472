.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.root {
	composes: ellipsis;

	max-width: 165px;
	color: rgb(var(--kit-colors-charcoal-800));

	@media screen and (min-width: 400px) {
		max-width: 169px;
	}

	@media (--tablet) {
		max-width: 210px;
	}
}

.title {
	display: block;
	margin-bottom: 24px;
}

.list {
	--margin-bottom: calc(var(--unit) * 2.5);

	display: flex;
	margin: 0 0 calc(var(--margin-bottom) * -1) 0;
	padding: 0;
	flex-wrap: wrap;
	list-style-type: none;
}

.listItem {
	width: 100%;
	margin-bottom: var(--margin-bottom);

	& > a {
		text-decoration: none;

		&:hover {
			color: inherit;
			text-decoration: underline;
		}
	}
}
